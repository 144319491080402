import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataUpdated } from '../../../redux/data-updated/action';

import { Button, Modal, notification, Tag, Tooltip } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { NotificationPlacement } from 'antd/lib/notification';

import PostPatientConsentService from '../../../api/patient-consent/post-patient-consent';
import ConsentFormsTableSelection from '../../../pages/dashboards/consent/ConsentFormsTable';

import { calculate_age } from '../../../utils/ageCalculate';

import { IPatient } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

interface IConsentSend {
  patient: IPatient;
  isText?: boolean;
}
export default function ConsentSend({ patient, isText = false }: IConsentSend) {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const { id, birthDay } = patient;
  const [visible, setVisible] = useState(false);
  const closeModal = () => setVisible(false);
  const openModal = () => setVisible(true);
  const dispatch = useDispatch();
  const updated = useSelector((state: any) => state.updated);
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  const handleSendConsent = (consent, surrogateId, consentType, subTenantId) => {
    if (consent.length < 1) {
      openNotification('bottomRight', 'error', t.notification_error_missing_consent_selection, '');
      return;
    }
    if (patient.gdprStatus !== 'approved') {
      openNotification('bottomRight', 'error', t.notification_error_missing_gdpr, '');
      return;
    }
    if (calculate_age(birthDay) < 18 && !surrogateId) {
      openNotification('bottomRight', 'error', t.notification_error_underage, '');
      return;
    }

    const requestBody = {
      patientId: id,
      consentGroupId: consent[0].groupId,
      surrogateId: surrogateId,
      type: consentType,
      subTenantId: subTenantId
    }

    if (!surrogateId) {
      delete requestBody.surrogateId;
    }

    if (!subTenantId) {
      delete requestBody.subTenantId
    }

    PostPatientConsentService(requestBody)
      .then(() => {
        openNotification('bottomRight', 'success', t.notification_successful_consent_sent, '');
        setVisible(false);
        dispatch(setDataUpdated(!updated));
      })
      .catch((err) => {
        openNotification('bottomRight', 'error', t.notification_error_consent_sent, '');
      });
  };

  return (
    <>
      {contextHolder}
      <Tooltip title={t.patient_operations_operation_tips_send_consent_text}>
        {isText ? (
          <Tag color={'#f5222d'} style={{ cursor: 'pointer' }}>
            <span onClick={openModal}>{t.patient_operations_send_consent_popup_title}</span>
          </Tag>
        ) : (
          <Button
            style={{ backgroundColor: '#ad6800', border: 'none' }}
            shape='circle'
            onClick={openModal}
          >
            <SendOutlined />
          </Button>
        )}
      </Tooltip>
      <Modal
        width={'80%'}
        visible={visible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        title={
          <span style={{ fontSize: '1.5em' }}>{t.patient_operations_send_consent_popup_title}</span>
        }
      >
        <div>
          <h5>
            {t.patient_operations_send_consent_popup_patient_name_text}{' '}
            {`${patient.firstName} ${patient.lastName}`}
          </h5>
        </div>
        <ConsentFormsTableSelection
          patient={patient}
          sendConsent={handleSendConsent}
          perPage={5}
          isProcess
        />
      </Modal>
    </>
  );
}
