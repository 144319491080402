/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataUpdated } from '../../../redux/data-updated/action';
import moment from 'moment';

import { Button, Input, Modal, notification, Table, Tag, Tooltip } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { NotificationPlacement } from 'antd/lib/notification';

import AddPatient from '../../../layout/components/patients/AddPatient';
import AddSurrogate from '../../../layout/components/surrogate/AddSurrogate';
import GdprSmsAccept from '../../../layout/components/gdprSend/GdprSmsAccept';
import GdprSendSms from '../../../layout/components/gdprSend/GdprSendSms';
import DeletePatient from '../../../layout/components/patients/DeletePatient';
import ConsentSend from '../../../layout/components/consentSend/ConsentSend';
import ShowSurrogate from '../../../layout/components/surrogate/ShowSurragetes';
import ConsentSmsAccept from '../../../layout/components/consentSend/ConsentSmsAccept';
import { DisplayPhoneNumber } from '../../../layout/components/phoneFormat/PhoneFormat';

import statusCode from '../../../utils/statusCode';
import ShowPatientConsent from '../patientsConsents/ShowConsent';

import DeletePatientService from '../../../api/patient/delete-patient';
import GetAllPatientService from '../../../api/patient/get-patients';

import { IPatient } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

type Props = {
  onEditPatient?: (patient: IPatient) => void;
  onDeletePatient?: (id: string) => void;
  visibleAddPatientButton?: boolean;
  handleSelectedRows?: React.Dispatch<React.SetStateAction<IPatient[]>>;
  defaultSelectedRows?: IPatient[];
};

const PatientsTable = ({
  visibleAddPatientButton = false,
  handleSelectedRows,
  defaultSelectedRows = []
}: Props) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const dispatch = useDispatch();
  const updated = useSelector((state: any) => state.updated);
  const [api, contextHolder] = notification.useNotification();
  const [patients, setPatients] = useState<IPatient[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [filteredColumns, setFilteredColumns] = useState([]);
  const [filterColumns, setFilterColumns] = useState({
    firstName: true,
    lastName: true,
    tckn: false,
    gsm: true,
    email: false,
    birthDay: true,
    gdprStatus: true,
    patientConsent: true,
    actions: true
  });

  const onClose = () => setVisible(false);

  const actions = (patient: IPatient) => (
    <div className='buttons-list nowrap'>
      <AddPatient componentType={'update'} patient={patient} />
      <AddSurrogate patient={patient} componentType='add' />
      {patient.hasSurrogate && <ShowSurrogate patient={patient} />}
      <ConsentSend patient={patient} />
      <ShowPatientConsent patient={patient} />
      <DeletePatient onAccept={() => handleDeletePatient(patient.id)} />
    </div>
  );

  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  const handleDeletePatient = (id) => {
    setLoading(true);
    DeletePatientService(id)
      .then(() => {
        dispatch(setDataUpdated(!updated));
        openNotification('bottomRight', 'success', t.notification_successful_patient_delete, '');
      })
      .catch((err) => {
        openNotification(
          'bottomRight',
          'error',
          err?.response?.data?.message ?? t.notification_error_patient_delete,
          ''
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPatientGDPRForm = (fileId) => {
    if (fileId) {
      window.open(
        `${process.env.REACT_APP_API_SERVICES_BASE_URL}/patient-gdpr/${fileId}/pdf`,
        '_blank'
      );
    }
  };

  useEffect(() => {
    handleGetPatient();
  }, [updated, currentPage]);

  const columns = [
    {
      key: 'firstName',
      dataIndex: 'firstName',
      title: t.patient_operations_table_column_name_text,
      render: (firstName) => <strong>{firstName}</strong>
    },
    {
      key: 'lastName',
      dataIndex: 'lastName',
      title: t.patient_operations_table_column_surname_text,
      render: (lastName) => <strong>{lastName}</strong>
    },
    {
      key: 'tckn',
      dataIndex: 'tckn',
      title: t.patient_operations_table_column_id_text,
      render: (tckn) => (
        <span className='nowrap' style={{ minWidth: 125, display: 'block', color: '#a5a5a5' }}>
          {tckn}
        </span>
      )
    },
    {
      key: 'gsm',
      dataIndex: 'gsm',
      title: t.patient_operations_table_column_gsm_text,
      render: (phone) => (
        <span className='d-flex align-baseline nowrap' style={{ color: '#336cfb' }}>
          <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} />
          {DisplayPhoneNumber(phone)}
        </span>
      )
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: t.patient_operations_table_column_email_text,
      render: (email) => <span style={{ minWidth: 125, display: 'block' }}>{email}</span>
    },
    {
      key: 'birthDay',
      dataIndex: 'birthDay',
      title: t.patient_operations_table_column_dob_text,
      render: (birthDay) => (
        <span style={{ minWidth: 100, display: 'block' }}>
          {moment(birthDay).format('DD/MM/YYYY')}
        </span>
      )
    },
    {
      key: 'gdprStatus',
      title: t.patient_operations_table_column_gdpr_status_text,
      render: (patient: IPatient) => {
        return (
          <>
            {patient.gdprStatus == 'unapproved' && <GdprSendSms patient={patient} />}
            {patient.gdprStatus == 'pending' && <GdprSmsAccept patient={patient} />}
            {patient.gdprStatus == 'approved' && (
              <Tag color={'#299617'}>
                <Tooltip title={t.patient_operations_operation_tips_display_gdpr_text}>
                  {' '}
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => getPatientGDPRForm(patient.lastPatientGdpr.id)}
                  >
                    {t[statusCode(patient.gdprStatus)]}
                  </span>
                </Tooltip>
              </Tag>
            )}
          </>
        );
      }
    },
    {
      key: 'patientConsent',
      title: t.patient_operations_table_column_consent_status_text,
      render: (patient: IPatient) => {
        if (patient.lastPatientConsent) {
          return (
            <>
              {patient.lastPatientConsent.status === 'pending' && (
                <ConsentSmsAccept patient={patient} fromConsentList={false} />
              )}
              {patient.lastPatientConsent.status === 'timeout' && (
                <ConsentSend isText patient={patient} />
              )}
              {patient.lastPatientConsent.status === 'successful' && (
                <Tag color={'#299617'}>
                  <span>{t.patient_operations_table_gdpr_approved_status_text}</span>
                </Tag>
              )}
            </>
          );
        } else return <ConsentSend isText patient={patient} />;
      }
    },
    {
      key: 'actions',
      title: t.patient_operations_table_column_operations_text,
      render: !visibleAddPatientButton ? actions : null
    }
  ];

  useEffect(() => {
    const data = columns.filter((el) => filterColumns[el.key]);
    setFilteredColumns(data);
  }, [filterColumns]);

  const pagination = {
    pageSize: itemsPerPage,
    current: currentPage,
    total: totalItems,
    showSizeChanger: false
  };

  const [filterText, setFilterText] = useState('');

  //fonksiyonlardan önce kurulum
  let typingTimer; //zamanlayıcı tanımlayıcısı

  //keyup'ta geri sayımı başlat
  const onKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(handleGetPatient, 500);
  };

  //tuşa basıldığında, geri sayımı temizle
  const onKeyDown = () => {
    clearTimeout(typingTimer);
  };

  //kullanıcı yazmayı bitirdiğinde çalışacak fonksiyon
  const handleGetPatient = () => {
    if (filterText.length > 2 || filterText.length < 1) {
      const params = { search: filterText, page: currentPage, limit: 10 };
      GetAllPatientService(params)
        .then((res) => {
          setItemsPerPage(res?.meta?.itemsPerPage);
          setTotalItems(res?.meta?.totalItems);
          setPatients(res.data);
          openNotification('bottomRight', 'success', t.notification_successful_patients_loaded, '');
        })
        .catch((err) => {
          openNotification(
            'bottomRight',
            'error',
            err?.response?.data?.message ?? t.notification_error_patients_loaded,
            ''
          );
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      {contextHolder}
      <div style={{ display: 'flex' }}>
        {!visibleAddPatientButton && <AddPatient componentType={'add'} />}
        <div style={{ maxWidth: 200, margin: '0 0 0 12px' }}>
          <Modal
            title={<h3>{t.general_column_filter_text}</h3>}
            visible={visible}
            footer={null}
            onCancel={onClose}
            destroyOnClose
          >
            {columns.map((column) => {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ width: 100 }}>{column.title}</span>
                  <input
                    type='checkbox'
                    name={column.key}
                    checked={filterColumns[column.key]}
                    onChange={() =>
                      setFilterColumns((state) => ({
                        ...state,
                        [column.key]: !filterColumns[column.key]
                      }))
                    }
                  />
                </div>
              );
            })}
          </Modal>
          <Button type='primary' onClick={() => setVisible(true)}>
            {t.patient_operations_table_column_button_text}
          </Button>
        </div>
        <div style={{ maxWidth: 200, margin: '0 0 0 12px' }}>
          <Tooltip title={t.general_search_tip_text}>
            <Input
              type='text'
              id='searchInput'
              placeholder={t.patient_operations_search_bar_placeholder}
              onKeyUp={onKeyUp}
              onKeyDown={onKeyDown}
              onChange={(e) => setFilterText(e.target.value)}
            />
          </Tooltip>
        </div>
      </div>
      <Table
        onChange={(pagination) => {
          setCurrentPage(pagination.current);
        }}
        pagination={pagination}
        className='accent-header'
        rowKey='id'
        dataSource={patients}
        columns={filteredColumns}
        loading={loading}
      />
    </>
  );
};

export default PatientsTable;
