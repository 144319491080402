interface Translations {
  [key: string]: {
    [key: string]: string;
  };
}

export const translations: Translations = {
  en: {
    sidebar_mainpage_text: 'Main Page',
    sidebar_users_text: 'My Users',
    sidebar_operations_title: 'OPERATIONS',
    sidebar_patient_consent_operations_text: 'Patient/Consent Operations',
    sidebar_packaging_text: 'Purchase Package',
    sidebar_reports_title: 'REPORTS',
    sidebar_consent_list_text: 'My Consent Forms',
    sidebar_gdpr_list_text: 'My GDPR Forms',
    sidebar_patient_list_text: 'Patient List',
    sidebar_consent_status_list_text: 'Consent Request Status List',
    sidebar_sponsors_text: 'Sponsors',
    sidebar_news_text: 'News',
    sidebar_gdpr_upload_text: 'Upload GDPR Form',
    sidebar_consent_upload_text: 'Upload Consent Form',
    sidebar_promo_list_text: 'Promo Codes',
    sidebar_sponsored_text: 'Sponsored by',
    sidebar_add_patient_text: 'Add Patient',
    sidebar_subtenants_text: 'Doctors',
    main_page_card_consent_sent_text: 'Consent Forms Sent',
    main_page_card_consent_approved_text: 'Consent Forms Approved',
    main_page_card_consent_unapproved_text: 'Consent Forms Unapproved',
    main_page_news_medionam_news_text: 'MediOnam News',
    main_page_news_other_news_text: 'News',
    settings_profile_text: 'Profile',
    settings_invoice_text: 'Billing Information',
    settings_logout_text: 'Logout',
    users_title: 'My Users',
    users_add_user_text: 'Add User',
    users_card_delete_text: 'Remove',
    users_card_send_again_text: 'Send Again',
    users_card_waiting_approval_text: 'Waiting Approval',
    users_popup_add_user_title: 'Add User',
    users_popup_add_user_email_text: 'E-Mail',
    users_popup_add_user_email_placeholder: 'E-Mail',
    users_popup_add_user_role_text: 'Role',
    users_popup_add_user_role_placeholder: 'Pick Role',
    users_popup_add_user_cancel: 'Cancel',
    users_popup_add_user_invite: 'Invite',
    users_popup_user_type_assistant_text: 'Assistant',
    users_popup_remove_user_title: 'Are you sure you want to remove the user?',
    users_popup_remove_cancel_text: 'Cancel',
    users_popup_remove_remove_text: 'Remove',
    users_notification_invitation_sent: "Invitation has been sent to user's email address.",
    users_notification_invitation_resent: "Invitation has been resent to user's email address.",
    users_notification_invitation_removed: 'User has been successfully removed.',
    // Patient Consent Operations Localization
    patient_operations_title: 'Patient/Consent Operations',
    patient_operations_table_column_button_text: 'Table Columns',
    patient_operations_search_bar_placeholder: 'Search',
    patient_operations_table_column_name_text: 'Name',
    patient_operations_table_column_surname_text: 'Surname',
    patient_operations_table_column_id_text: 'T.C. ID No',
    patient_operations_table_column_gsm_text: 'Mobile Phone',
    patient_operations_table_column_email_text: 'Email',
    patient_operations_table_column_dob_text: 'Date of Birth',
    patient_operations_table_column_gdpr_status_text: 'GDPR Status',
    patient_operations_table_column_consent_status_text: 'Consent Status',
    patient_operations_table_column_operations_text: 'Operations',
    patient_operations_operation_tips_update_patient_text: 'Update Patient',
    patient_operations_operation_tips_add_surrogate_text: 'Add Surrogate',
    patient_operations_operation_tips_surrogate_list_text: 'Surrogate List',
    patient_operations_operation_tips_send_consent_text: 'Click to send consent',
    patient_operations_operation_tips_display_gdpr_text: 'Click to display',
    patient_operations_operation_tips_patient_consents_text: 'Patient Consents',
    patient_operations_operation_tips_remove_patient_text: 'Delete Patient',
    patient_operations_table_gdpr_approved_status_text: 'Approved',
    patient_operations_table_consent_approved_status_text: 'Approved',
    patient_operations_table_gdpr_unapproved_status_text: 'Send GDPR',
    patient_operations_table_consent_unapproved_status_text: 'Send Consent',
    patient_operations_table_consent_timeout_status_text: 'Timeout',
    patient_operations_table_gdpr_pending_status_text: 'Pending',
    patient_operations_table_consent_pending_status_text: 'Pending',
    patient_operations_table_consent_unsuccesful_status_text: 'Unsuccessful',
    // Add Patient Popup Localization
    patient_operations_add_patient_popup_title: 'Add Patient',
    patient_operations_add_patient_popup_turkish_text: 'Turkish Citizen',
    patient_operations_add_patient_popup_foreign_text: 'Foreign Citizen',
    patient_operations_add_patient_popup_name_text: 'Name',
    patient_operations_add_patient_popup_surname_text: 'Surname',
    patient_operations_add_patient_popup_tc_id_text: 'T.C. ID No',
    patient_operations_add_patient_popup_passport_no_text: 'Passport Number',
    patient_operations_add_patient_popup_dob_text: 'Date of Birth',
    patient_operations_add_patient_popup_email_text: 'Email',
    patient_operations_add_patient_popup_gsm_text: 'Mobile Phone',
    patient_operations_add_patient_popup_surrogate_title: 'Surrogate',
    patient_operations_add_patient_popup_surrogate_name_text: 'Name',
    patient_operations_add_patient_popup_surrogate_surname_text: 'Surname',
    patient_operations_add_patient_popup_surrogate_tc_id_text: 'T.C. ID No',
    patient_operations_add_patient_popup_surrogate_passport_no_text: 'Passport Number',
    patient_operations_add_patient_popup_surrogate_email_text: 'Email',
    patient_operations_add_patient_popup_surrogate_gsm_text: 'Mobile Phone',
    patient_operations_add_patient_popup_cancel_text: 'Cancel',
    patient_operations_add_patient_popup_add_text: 'Add Patient',
    patient_operations_add_patient_foreign_phone_info_text:
      '*Forms will be sent to the email address of patients who do not have a Turkish phone number.',
    patient_list_pending_consent_text: 'Pending Approval',
    patient_list_tip_operations_text:
      'You can send consent forms on Patient/Consent Operations page.',
    // Update Patient Popup Localization
    patient_operations_update_patient_popup_title: 'Update Patient',
    patient_operations_update_patient_popup_turkish_text: 'Turkish Citizen',
    patient_operations_update_patient_popup_foreign_text: 'Foreign Citizen',
    patient_operations_update_patient_popup_name_text: 'Name',
    patient_operations_update_patient_popup_surname_text: 'Surname',
    patient_operations_update_patient_popup_tc_id_text: 'T.C. ID No',
    patient_operations_update_patient_popup_passport_no_text: 'Passport Number',
    patient_operations_update_patient_popup_dob_text: 'Date of Birth',
    patient_operations_update_patient_popup_email_text: 'Email',
    patient_operations_update_patient_popup_gsm_text: 'Mobile Phone',
    patient_operations_update_patient_popup_surrogate_title: 'Surrogate',
    patient_operations_update_patient_popup_surrogate_name_text: 'Name',
    patient_operations_update_patient_popup_surrogate_surname_text: 'Surname',
    patient_operations_update_patient_popup_surrogate_tc_id_text: 'T.C. ID No',
    patient_operations_update_patient_popup_surrogate_passport_no_text: 'Passport No',
    patient_operations_update_patient_popup_surrogate_email_text: 'Email',
    patient_operations_update_patient_popup_surrogate_gsm_text: 'GSM',
    patient_operations_update_patient_popup_cancel_text: 'Cancel',
    patient_operations_update_patient_popup_add_text: 'Update',
    patient_operations_update_patient_foreign_phone_info_text:
      '*Forms will be sent to the email address of patients without a Turkish phone number.',
    // Doctors Localization
    doctors_forms_table_column_title_text: 'Title',
    doctors_forms_table_column_firstname_text: 'First Name',
    doctors_forms_table_column_lastname_text: 'Last Name',
    doctors_forms_table_column_gsm_text: 'GSM',
    doctors_forms_table_column_email_text: 'E-Mail',
    doctors_add_user_text: 'Add Doctor',
    doctors_add_user_popup_code_placeholder: 'Doctor Code',
    doctors_add_user_popup_code_title: 'Code',
    // Add Surrogate Popup Localization
    patient_operations_add_surrogate_popup_title: 'Add Surrogate',
    patient_operations_add_surrogate_popup_name_text: 'Name',
    patient_operations_add_surrogate_popup_surname_text: 'Surname',
    patient_operations_add_surrogate_popup_tc_id_text: 'T.C. ID No',
    patient_operations_add_surrogate_popup_email_text: 'Email',
    patient_operations_add_surrogate_popup_gsm_text: 'Mobile Phone',
    patient_operations_add_surrogate_popup_cancel_text: 'Cancel',
    patient_operations_add_surrogate_popup_add_text: 'Add Surrogate',
    // Surrogate List Popup Localization
    patient_operations_surrogate_list_popup_title: 'Surrogate List',
    patient_operations_surrogate_list_popup_table_column_name_text: 'Name',
    patient_operations_surrogate_list_popup_table_column_surname_text: 'Surname',
    patient_operations_surrogate_list_popup_table_column_id_text: 'T.C. ID No',
    patient_operations_surrogate_list_popup_table_column_gsm_text: 'Mobile Phone',
    patient_operations_surrogate_list_popup_table_column_email_text: 'Email',
    patient_operations_surrogate_list_popup_table_column_gdpr_status_text: 'GDPR Status',
    patient_operations_surrogate_list_popup_table_column_operations_text: 'Operations',
    patient_operations_surrogate_list_popup_tips_update_surrogate_text: 'Update Surrogate',
    // Update Surrogate Popup Localization
    patient_operations_update_surrogate_popup_title: 'Update Surrogate',
    patient_operations_update_surrogate_popup_name_text: 'Name',
    patient_operations_update_surrogate_popup_surname_text: 'Surname',
    patient_operations_update_surrogate_popup_tc_id_text: 'T.C. ID No',
    patient_operations_update_surrogate_popup_email_text: 'Email',
    patient_operations_update_surrogate_popup_gsm_text: 'Mobile Phone',
    patient_operations_update_surrogate_popup_cancel_text: 'Cancel',
    patient_operations_update_surrogate_popup_add_text: 'Update Surrogate',
    // Send Consent Popup Localization
    patient_operations_send_consent_popup_title: 'Send Consent',
    patient_operations_send_consent_popup_patient_name_text: 'Patient Name:',
    patient_operations_send_consent_popup_contact_choice_text: 'Contact Preference:',
    patient_operations_send_consent_popup_consent_type_text: 'Consent Purpose:',
    patient_operations_send_consent_popup_consent_search_text: 'Search Consent',
    patient_operations_send_consent_popup_table_column_consent_name_text: 'Consent Name',
    patient_operations_send_consent_popup_table_column_consent_status_text: 'Status',
    patient_operations_send_consent_popup_table_column_consent_version_text: 'Version',
    patient_operations_send_consent_popup_table_column_consent_upload_date_text: 'Upload Date',
    patient_operations_send_consent_popup_table_column_consent_operations_text: 'Operations',
    patient_operations_send_consent_popup_consent_type_approve_text: 'Approve',
    patient_operations_send_consent_popup_consent_type_reject_text: 'Reject',
    patient_operations_send_consent_popup_consent_type_cancel_text: 'Cancel',
    patient_operations_send_consent_popup_send_text: 'Send',
    patient_operations_send_consent_popup_consent_uptodate_text: 'Up to Date',
    patient_operations_send_consent_popup_consent_not_uptodate_text: 'Old',
    // Send Consent Popup Localization
    patient_operations_patient_consents_popup_title: 'Patient Consents',
    patient_operations_patient_consents_popup_patient_name_text: 'Patient Name:',
    patient_operations_patient_consents_popup_table_column_consent_name_text: 'Consent Name',
    patient_operations_patient_consents_popup_table_column_consent_status_text: 'Status',
    patient_operations_patient_consents_popup_table_column_consent_type_text: 'Consent Type',
    patient_operations_patient_consents_popup_table_column_consent_request_date_text:
      'Request Date',
    patient_operations_patient_consents_popup_table_column_consent_operations_text: 'Operations',
    patient_operations_patient_consents_popup_table_column_view_consent_text: 'Click to view',
    patient_operations_patient_consents_popup_consent_type_approve_text: 'Approve',
    patient_operations_patient_consents_popup_consent_type_reject_text: 'Reject',
    patient_operations_patient_consents_popup_consent_type_cance_text: 'Cancel',
    // Delete Patient Popup Localization
    patient_operations_patient_delete_patient_popup_text:
      'Are you sure you want to delete the patient?',
    patient_operations_patient_delete_patient_popup_cancel_text: 'Cancel',
    patient_operations_patient_delete_patient_popup_delete_text: 'Delete',
    // Sponsors Localization
    sponsors_title: 'Sponsors',
    sponsors_table_column_name_text: 'Name',
    sponsors_table_column_description_text: 'Description',
    sponsors_table_column_operations_text: 'Operations',
    sponsors_add_sponsor_text: 'Add Sponsor',
    sponsors_operations_tips_update_sponsor_text: 'Update Sponsor',
    sponsors_operations_tips_create_code_text: 'Create Code',
    sponsors_operations_tips_delete_sponsor_text: 'Delete Sponsor',
    sponsors_delete_sponsor_popup_text: 'Are you sure you want to delete the sponsor?',
    sponsors_delete_sponsor_popup_cancel_text: 'Cancel',
    sponsors_delete_sponsor_popup_delete_text: 'Delete',
    sponsors_add_sponsor_popup_name_text: 'Name',
    sponsors_add_sponsor_popup_description_text: 'Description',
    sponsors_create_code_popup_name_text: 'Name',
    sponsors_create_code_popup_description_text: 'Description',
    sponsors_create_code_popup_code_number_text: 'Code Number',
    sponsors_create_code_popup_prefix_text: 'Prefix',
    sponsors_create_code_popup_end_date_text: 'Expiry Date',
    sponsors_create_code_popup_packages_text: 'Packages',
    // News Localization
    news_title: 'Sponsors',
    news_table_column_title_text: 'Title',
    news_table_column_description_text: 'Description',
    news_table_column_sponsor_name_text: 'Sponsor Name',
    news_table_column_operations_text: 'Operations',
    news_add_news_text: 'Add News',
    news_add_news_popup_title_text: 'Title',
    news_add_news_popup_description_text: 'Description',
    news_add_news_popup_target_text: 'Target URL',
    news_add_news_popup_image_url_text: 'Image URL',
    news_add_news_popup_sponsor_text: 'Sponsor',
    news_add_news_popup_select_sponsor_text: 'Pick Sponsor',
    news_operations_tips_update_news_text: 'Update News',
    news_operations_tips_delete_news_text: 'Delete News',
    news_delete_news_popup_text: 'Are you sure you want to delete the news?',
    news_update_news_text: 'Update News',
    // Package Localization
    purchase_package_title: 'Purchase Package',
    purchase_package_table_column_name_text: 'Package Name',
    purchase_package_table_column_description_text: 'Description',
    purchase_package_table_column_package_duration_text: 'Package Duration',
    purchase_package_table_column_package_price_text: 'Price',
    purchase_package_table_column_package_type_text: 'Package Type',
    purchase_package_table_column_consents_text: 'Consent Forms',
    purchase_package_table_column_operations_text: 'Operations',
    purchase_package_add_package_text: 'Add Package',
    purchase_package_update_package_text: 'Update Package',
    purchase_package_consent_purchase_text: 'Payment Page',
    purchase_package_tips_view_package_text: 'View',
    purchase_package_tips_update_package_text: 'Update Package',
    purchase_package_tips_delete_package_text: 'Delete Package',
    purchase_package_delete_package_popup_text: 'Are you sure you want to delete the package?',
    purchase_package_content_popup_consent_title: 'Package Content',
    purchase_package_content_popup_consent_text: 'Consent Name',
    purchase_package_content_popup_consent_detail_text: 'Detail',
    purchase_package_content_popup_consent_creation_date_text: 'Upload Date',
    purchase_package_month_text: 'Months',
    purchase_package_year_text: 'Year',
    purchase_package_single_text: 'Single',
    purchase_package_multiple_text: 'Multiple',
    // GDPR Upload Localization
    gdpr_upload_default_gdpr_text: 'Set as Default GDPR',
    // Consent Upload Localization
    consent_upload_create_consent_document_text: 'Create Consent',
    consent_upload_create_consent_consent_name: 'Consent Name',
    consent_upload_create_consent_consent_description: 'Consent Description',
    consent_upload_create_consent_consent_duration: 'Consent Duration',
    consent_upload_create_consent_consent_type: 'Consent Type',
    consent_upload_create_consent_consent_price: 'Price',
    // Promo Codes Localization
    promo_codes_table_column_name_text: 'Promo Name',
    promo_codes_table_column_quantity_text: 'Code Quantity',
    promo_codes_table_column_sponsor_text: 'Sponsor',
    promo_codes_table_column_expiration_date_text: 'Expiration Date',
    promo_codes_table_column_operations_text: 'Operations',
    promo_codes_tips_view_text: 'View Codes',
    promo_codes_tips_delete_text: 'Delete Promotion',
    promo_codes_table_column_description_text: 'Description',
    promo_codes_table_column_prefix_text: 'Prefix',
    promo_codes_delete_promotion_popup_text: 'Are you sure you want to delete the promotion?',
    promo_codes_codes_popup_title: 'Promotion Codes',
    promo_codes_codes_popup_table_column_code_text: 'Code',
    promo_codes_codes_popup_table_column_creation_date_text: 'Creation Date',
    promo_codes_codes_popup_table_column_code_status_text: 'Code Status',
    promo_codes_codes_popup_table_column_email_text: 'E-Mail',
    promo_codes_codes_popup_table_column_usage_date_text: 'Usage Date',
    promo_codes_codes_popup_table_column_expiry_date_text: 'Expiry Date',
    promo_codes_codes_popup_table_column_status_text: 'Status',
    promo_codes_codes_popup_used_text: 'Used',
    promo_codes_codes_popup_unused_text: 'Unused',
    promo_codes_codes_popup_active_text: 'Active',
    promo_codes_codes_popup_expired_text: 'Expired',
    // GDPR Forms  Localization
    gdpr_forms_table_upload_gdpr_text: 'Upload GDPR',
    gdpr_forms_table_column_name_text: 'Form Name',
    gdpr_forms_table_column_version_text: 'Version',
    gdpr_forms_table_column_creator_text: 'Creator',
    gdpr_forms_table_column_creation_date_text: 'Creation Date',
    gdpr_forms_table_expired_text: 'Expired',
    gdpr_forms_table_column_operations_text: 'Operations',
    gdpr_forms_table_active_text: 'Active',
    gdpr_forms_table_activate_text: 'Activate',
    gdpr_forms_tips_view_text: 'Click to view',
    gdpr_forms_tips_activate_text: 'Click to set this as selected gdpr',
    // Profile Localization
    profile_title_text: 'Title',
    profile_name_text: 'Name',
    profile_surname_text: 'Surname',
    profile_gsm_text: 'Mobile Number',
    profile_save_changes_text: 'Save Changes',
    profile_current_password_text: 'Current Password',
    profile_new_password_text: 'New Password',
    profile_new_password_again_text: 'Repeat Password',
    profile_update_password_text: 'Update Password',
    profile_promotion_code_text: 'Promotion Code',
    profile_active_promotion_code_text: 'Active Promotion Code:',
    profile_promotion_expiration_date_text: 'Expiration Date:',
    // Billing Localization
    billing_information_address_title_text: 'Adress Name',
    billing_information_address_name_text: 'Name',
    billing_information_address_surname_text: 'Surname',
    billing_information_address_city_text: 'City',
    billing_information_address_county_text: 'County',
    billing_information_address_address_text: 'Address',
    billing_information_address_email_text: 'E-Mail',
    billing_information_address_gsm_text: 'Mobile Number',
    billing_information_address_corporate_address_text: 'Corporate Adress',
    billing_information_address_corporate_name_text: 'Company Name',
    billing_information_address_tax_office_text: 'Tax Office',
    billing_information_address_tax_number_text: 'Tax Number / ID Number',
    billing_information_address_e_invoice_owner_text: 'E-invoice',
    billing_information_save_text: 'Save',
    // Sign in Localization
    sign_in_title: 'Sign In',
    sign_in_subtitle: 'Sign in to access to your account',
    sign_in_email_text: 'E-Mail',
    sign_in_password_text: 'Password',
    sign_in_forgot_password_text: 'Forgot Password',
    sign_in_register_text: 'Sign Up!',
    sign_in_register_question_text: "Don't have an account?",
    // Sign up Localization
    sign_up_title: 'Sign Up',
    sign_up_subtitle: 'Fill the form to sign up',
    sign_up_name_text: 'Name',
    sign_up_surname_text: 'Surname',
    sign_up_email_text: 'E-Mail',
    sign_up_gsm_text: 'Mobile Number',
    sign_up_password_text: 'Password',
    sign_up_repeat_password_text: 'Repeat Password',
    sign_up_promotion_code_text: 'Promotion Code',
    sign_up_terms_and_conditions_text: 'User Agreement and Privacy Policy',
    sign_up_terms_accept_text: 'I accept',
    sign_up_login_question_text: 'Have an account?',
    sign_up_login_text: 'Sign in!',
    // Forgot Password Localization
    forgot_password_title: 'Reset Password',
    forgot_password_subtitle:
      'Enter your E-Mail to reset password. We will send you a mail to reset your password briefly.',
    forgot_password_email_text: 'E-Mail',
    forgot_password_send_text: 'Send',
    forgot_password_back_text: 'Back',
    // OTP Input
    sms_accept_gdpr_label: 'GDPR Approve',
    sms_communication_preference_text: 'Contact Preference:',
    sms_send_text: 'Send',
    sms_accept_label: 'Consent Approve',
    sms_accept_send_again_text: 'Send code again',
    sms_accept_approve_text: 'Approve',
    sms_accept_send_again_question: 'Did not receive the code?',
    sms_accept_code_text: 'OTP Code',
    // Input Error Localization
    input_error_invalid_name: 'Name and surname must have at least 2 characters.',
    input_error_missing_newpassword: 'Please input new password.',
    input_error_invalid_password:
      'Password must have at least 8 characters, 1 number and 1 capital letter.',
    input_error_invalid_login_password: 'Please input password.',
    input_error_missing_promotion_code: 'Missing promotion code.',
    input_error_invalid_tc_id: 'Incorrect or missing T.C. ID no.',
    input_error_invalid_passport_id: 'Incorrect or missing passport no.',
    input_error_invalid_dob: 'Date of birth cannot be missing or a future date.',
    input_error_invalid_email: 'Missing or incorrect e-mail address.',
    input_error_invalid_gsm: 'Missing or incorrect mobile number.',
    input_error_invalid_file_name: 'Missing file name.',
    input_error_invalid_file_type: 'Missing file type.',
    input_error_missing_file: 'File not found.',
    input_error_missing_duration: 'Missing duration.',
    input_error_invalid_consent_information: 'Missing consent information.',
    input_error_invalid_consent_type: 'Missing consent type.',
    input_error_invalid_consent_price: 'Missing consent price.',
    input_error_invalid_user_role: 'Missing role.',
    input_error_missing_package_name: 'Missing package name.',
    input_error_missing_package_description: 'Missing package description.',
    input_error_missing_package_price: 'Missing package price.',
    input_error_missing_package_duration: 'Missing package duration.',
    input_error_missing_package_consent: 'Missing package consent.',
    input_error_missing_news_name: 'Missing news name.',
    input_error_missing_news_topic: 'Missing news topic.',
    input_error_missing_news_link: 'Missing news link.',
    input_error_missing_news_image_link: 'Missing news image.',
    input_error_missing_news_sponsor: 'Missing news sponsor.',
    input_error_missing_sponsor_name: 'Missing sponsor name.',
    input_error_missing_sponsor_description: 'Missing sponsor description.',
    input_error_missing_otp_code: 'Please input the 6-digit OTP code.',
    input_error_missing_promotion_name: 'Missing promotion name.',
    input_error_missing_promotion_description: 'Missing promotion description.',
    input_error_missing_promotion_quantity: 'Eksik promotion quantity.',
    input_error_missing_promotion_prefix: 'Missing prefix.',
    input_error_missing_promotion_expiration_date: 'Missing expiration date.',
    input_error_missing_promotion_package: 'Missing promotion package.',
    input_error_missing_address_name: 'Missing address name.',
    input_error_missing_address_city: 'Missing city.',
    input_error_missing_address_county: 'Missing county.',
    input_error_missing_address: 'Missing address.',
    input_error_missing_corporate_name: 'Missing corporation name.',
    input_error_missing_tax_registry: 'Missing tax registry.',
    input_error_missing_tax_number: 'Missing tax number.',
    input_error_missing_doctor_code: 'Missing doctor code.',
    input_error_not_matching_password: 'Password not matching.',
    // Notification Messages
    notification_successful_patient_update: 'Patient updated successfully.',
    notification_error_patient_update:
      'An error occurred while updating the patient. Please try again.',
    notification_successful_patient_add: 'Patient added successfully.',
    notification_error_patient_add: 'An error occurred while adding the patient. Please try again.',
    notification_successful_patient_delete: 'Patient deleted successfully.',
    notification_error_patient_delete:
      'An error occurred while deleting the patient. Please try again.',
    notification_successful_patients_loaded: 'Patient list loaded successfully.',
    notification_error_patients_loaded:
      'An error occurred while loading the patient list. Please try again.',
    notification_successful_forgot_password:
      'A password reset link has been sent to your email address.',
    notification_error_forgot_password:
      'An error occurred while sending the password reset link. Please try again.',
    notification_error_missing_consent_selection:
      'Please select the informed consent form you want to send.',
    notification_error_missing_gdpr:
      "Patient's KVKK form must be approved first in order to send informed consent.",
    notification_error_underage:
      'Informed consent forms for patients under the age of 18 must be sent to their legal guardian.',
    notification_successful_consent_sent:
      "Informed consent form has been successfully sent to the patient's communication preferences.",
    notification_error_consent_sent:
      'An error occurred while sending the informed consent form. Please try again.',
    notification_successful_billing_info_update: 'Billing information updated successfully.',
    notification_error_billing_info_update:
      'An error occurred while updating billing information. Please try again.',
    notification_info_billing_info_not_found: 'No billing information was found for the user.',
    notification_successful_billing_info_add: 'Billing information added successfully.',
    notification_error_billing_info_add:
      'An error occurred while adding billing information. Please try again.',
    notification_successful_consent_approved:
      'Informed consent form has been successfully approved.',
    notification_error_consent_approved:
      'An error occurred while approving the informed consent form. Please try again.',
    notification_successful_password_reset: 'Your password has been successfully reset.',
    notification_error_password_reset:
      'An error occurred while resetting your password. Please try again.',
    notification_successful_sign_up:
      'Your account has been successfully created. Please confirm your account by clicking on the link sent to your email address to log in.',
    notification_successful_sign_up_secretary: 'You are being redirected to sign in.',
    notification_error_sign_up: 'An error occurred while signing up. Please try again.',
    notification_error_sign_in: 'An error occurred while signing in. Please try again.',
    notification_successful_sign_in:
      'Successfully signed in. You are being redirected to the home page.',
    notification_error_underage_gdpr:
      'Patients under the age of 18 must have their GDPR approvals sent to a legal representative.',
    notification_error_missing_phone:
      'Please ensure that the patient has a valid mobile phone number.',
    notification_successful_gdpr_sent:
      "GDPR form has been successfully sent to the patient's communication preferences.",
    notification_error_gdpr_sent:
      'An error occurred while sending the GDPR form. Please try again.',
    notification_successful_gdpr_approved: 'GDPR form has been successfully approved.',
    notification_error_gdpr_approved:
      'An error occurred while approving the GDPR form. Please try again.',
    notification_successful_news_loaded: 'News successfully loaded.',
    notification_error_news_loaded: 'An error occurred while loading news. Please try again.',
    notification_successful_news_deleted: 'News successfully deleted.',
    notification_error_news_deleted: 'An error occurred while deleting news. Please try again.',
    notification_successful_news_added: 'News successfully added.',
    notification_error_news_added: 'An error occurred while adding news. Please try again.',
    notification_successful_news_updated: 'News successfully updated.',
    notification_error_news_updated: 'An error occurred while updating news. Please try again.',
    notification_error_get_promotions:
      'An error occurred while loading promotions. Please try again.',
    notification_successful_package_delete: 'Package successfully deleted.',
    notification_error_package_delete:
      'An error occurred while deleting the package. Please try again.',
    notification_successful_package_load: 'Package list successfully loaded.',
    notification_error_package_load:
      'An error occurred while loading the package list. Please try again.',
    notification_error_package_choose: 'Please select a package to purchase.',
    notification_successful_account_load: 'Profile information successfully loaded.',
    notification_error_account_load:
      'An error occurred while loading the profile information. Please try again.',
    notification_successful_account_update: 'Profile information successfully updated.',
    notification_error_account_update:
      'An error occurred while updating the profile information. Please try again.',
    notification_error_invalid_promotion_code:
      'Promotion code you entered is incorrect. Please check that you entered the code correctly.',
    notification_successful_promotion_code_entered:
      'Promotion code has been successfully added to your account.',
    notification_successful_password_change: 'Your password has been successfully changed.',
    notification_error_password_change:
      'An error occurred while changing your password. Please try again.',
    users_notification_invitation_error:
      'An error occurred while sending the invitation. Please try again.',
    notification_successful_file_upload: 'File successfully uploaded.',
    notification_error_file_upload: 'An error occurred while uploading the file. Please try again.',
    notification_successful_patient_list_get: 'Patient list successfully loaded.',
    notification_error_patient_list_get:
      'An error occurred while loading the patient list. Please try again.',
    notification_successful_surrogate_added: 'Surrogate successfully added.',
    notification_error_surrogate_added:
      'An error occurred while adding the surrogate. Please try again.',
    notification_successful_surrogate_updated: 'Surrogate successfully updated.',
    notification_error_surrogate_updated:
      'An error occurred while updating the surrogate. Please try again.',
    notification_successful_gdpr_loaded: 'GDPR forms successfully loaded.',
    notification_error_gdpr_loaded: 'An error occurred while loading GDPR forms. Please try again.',
    notification_successful_consents_loaded: 'Consent forms successfully loaded.',
    notification_error_consents_loaded:
      'An error occurred while loading consent forms. Please try again.',
    notification_successful_sponsor_add: 'Sponsor successfully added.',
    notification_error_sponsor_add: 'An error occurred while adding the sponsor. Please try again.',
    notification_successful_sponsor_update: 'Sponsor successfully updated.',
    notification_error_sponsor_update:
      'An error occurred while updating the sponsor. Please try again.',
    notification_error_sponsor_delete:
      'An error occurred while deleting the sponsor. Please try again.',
    notification_successful_sponsor_delete: 'Sponsor was deleted successfully.',
    notification_error_sponsor_load:
      'An error occurred while loading the sponsor list. Please try again.',
    notification_successful_sponsor_load: 'Sponsor list was loaded successfully.',
    notification_successful_code_created: 'Promotion code was created successfully.',
    notification_error_code_created:
      'An error occurred while creating the promotion code. Please try again.',
    notification_successful_code_deleted: 'Promotion code was deleted successfully.',
    notification_error_code_deleted:
      'An error occurred while deleting the promotion code. Please try again.',
    notification_successful_code_loaded: 'Promotion codes were loaded successfully.',
    notification_error_code_loaded:
      'An error occurred while loading the promotion codes. Please try again.',
    notification_error_past_expiration_date:
      'Expiration date for the promotion code cannot be set in the past. Please try again.',
    notification_error_package_form_not_found:
      'Consent groups could not be found. Please try again.',
    notification_successful_package_form_created: 'Package was created successfully.',
    notification_error_package_form_created:
      'An error occurred while creating the package. Please try again.',
    notification_successful_package_form_update: 'Package was updated successfully.',
    notification_error_package_form_update:
      'An error occurred while updating the package. Please try again.',
    notification_error_mail_invitation:
      'An error occurred while sending the invitation email. Please try again.',
    // General Usage
    general_table_column_text: 'Table Columns',
    general_column_filter_text: 'Filter Columns',
    general_create_text: 'Crate',
    general_cancel_text: 'Cancel',
    general_delete_text: 'Delete',
    general_update_text: 'Update',
    general_upload_text: 'Upload',
    general_folder_name: 'File Name',
    general_pick_folder_text: 'Select File',
    general_search_text: 'Search',
    general_patient_text: 'Patient',
    general_all_text: 'All',
    general_view_text: 'View',
    general_view_tip_text: 'Click to view',
    general_add_text: 'Add',
    general_search_tip_text: 'Enter at least 3 characters to begin your search.',

    // Corporate Account
    edit_profile_delete_corporate_conntection_text: 'Remove Corporate Connection',
    edit_profile_corporate_area_label: 'Corporate Account Code:',
    edit_profile_delete_code_text: 'Delete Code',
    edit_profile_generate_corporate_code_text: 'Generate Corporate Connection Code',
    edit_profile_delete_connection_notification: 'Corporate Connection Removed',
    edit_profile_delete_connection_error_notification: 'An error occurred while deleting corporate connection. Please try again.',
    edit_profile_delete_code_notification: 'Corporate Code Deleted',
    edit_profile_delete_code_error_notification: 'An error occurred while deleting corporate code. Please try again.',
    edit_profile_code_generated_notification: 'Corporate Connection Code Generated',
    edit_profile_code_generated_error_notification: 'An error occurred while generating corporate code. Please try again.',
    doctor_form_added_doctor_notification: 'Doctor Added Successfully',
    doctor_form_added_doctor_error_notification: 'An error occurred while adding doctor. Please try again.',
  },
  tr: {
    // Sidebar Localization
    sidebar_mainpage_text: 'Ana Sayfa',
    sidebar_users_text: 'Kullanıcılarım',
    sidebar_operations_title: 'İŞLEMLER',
    sidebar_patient_consent_operations_text: 'Hasta/Onam İşlemleri',
    sidebar_packaging_text: 'Paket Satın Al',
    sidebar_reports_title: 'RAPORLAR',
    sidebar_consent_list_text: 'Onam Formlarım',
    sidebar_gdpr_list_text: 'KVKK Formlarım',
    sidebar_patient_list_text: 'Hasta Listesi',
    sidebar_consent_status_list_text: 'Onam Talep Durum Listesi',
    sidebar_sponsors_text: 'Sponsorlar',
    sidebar_news_text: 'Haberler',
    sidebar_gdpr_upload_text: 'KVKK Form Yükle',
    sidebar_consent_upload_text: 'Onam Form Yükle',
    sidebar_promo_list_text: 'Promosyon Kodları',
    sidebar_sponsored_text: 'Sponsored by',
    sidebar_add_patient_text: 'Hasta Ekle',
    sidebar_subtenants_text: 'Doktorlarım',
    // Main Page Localization
    main_page_card_consent_sent_text: 'Talep Gönderilen Onam Formu Sayısı',
    main_page_card_consent_approved_text: 'Onaylanan Onam Sayısı',
    main_page_card_consent_unapproved_text: 'Tamamlanmayan Onam Sayısı',
    main_page_news_medionam_news_text: 'MediOnam Haberleri',
    main_page_news_other_news_text: 'Haberleri',
    // Settings Localization
    settings_profile_text: 'Profilim',
    settings_invoice_text: 'Fatura Bilgilerim',
    settings_logout_text: 'Çıkış Yap',
    // My Users Localization
    users_title: 'Kullanıcılarım',
    users_add_user_text: 'Kullanıcı Ekle',
    users_card_delete_text: 'Sil',
    users_card_send_again_text: 'Tekrar Gönder',
    users_card_waiting_approval_text: 'Onay Bekliyor',
    users_popup_add_user_title: 'Kullanıcı Ekle',
    users_popup_add_user_email_text: 'E-Posta',
    users_popup_add_user_email_placeholder: 'E-Posta',
    users_popup_add_user_role_text: 'Rol',
    users_popup_add_user_role_placeholder: 'Rol Seç',
    users_popup_add_user_cancel: 'İptal',
    users_popup_add_user_invite: 'Davet Gönder',
    users_popup_user_type_assistant_text: 'Asistan',
    users_popup_remove_user_title: 'Kullanıcıyı silmek istediğinizden emin misiniz?',
    users_popup_remove_cancel_text: 'İptal',
    users_popup_remove_remove_text: 'Sil',
    users_notification_invitation_sent: 'Davetiye kullanıcı e-posta adresine gönderildi.',
    users_notification_invitation_resent: 'Davetiye kullanıcı e-posta adresine tekrar gönderildi.',
    users_notification_invitation_removed: 'Kullanıcı başarıyla silindi.',

    // Patient Consent Operations Localization
    patient_operations_title: 'Hasta/Onam İşlemleri',
    patient_operations_table_column_button_text: 'Tablo Kolonları',
    patient_operations_search_bar_placeholder: 'Arama Yap',
    patient_operations_table_column_name_text: 'Ad',
    patient_operations_table_column_surname_text: 'Soyad',
    patient_operations_table_column_id_text: 'T.C. Kimlik No',
    patient_operations_table_column_gsm_text: 'GSM',
    patient_operations_table_column_email_text: 'E-Posta',
    patient_operations_table_column_dob_text: 'Doğum Tarihi',
    patient_operations_table_column_gdpr_status_text: 'KVKK Durum',
    patient_operations_table_column_consent_status_text: 'Onam Durum',
    patient_operations_table_column_operations_text: 'İşlemler',
    patient_operations_operation_tips_update_patient_text: 'Hasta Güncelle',
    patient_operations_operation_tips_add_surrogate_text: 'Vekil Ekle',
    patient_operations_operation_tips_surrogate_list_text: 'Vekil Listesi',
    patient_operations_operation_tips_send_consent_text: 'Onam göndermek için tıklayın',
    patient_operations_operation_tips_display_gdpr_text: 'Görüntülemek için tıklayın',
    patient_operations_operation_tips_patient_consents_text: 'Hastaya Ait Onamlar',
    patient_operations_operation_tips_remove_patient_text: 'Hasta Sil',
    patient_operations_table_gdpr_approved_status_text: 'Onaylandı',
    patient_operations_table_consent_approved_status_text: 'Onaylandı',
    patient_operations_table_gdpr_unapproved_status_text: 'KVKK Gönder',
    patient_operations_table_consent_unapproved_status_text: 'Onam Gönder',
    patient_operations_table_consent_timeout_status_text: 'Zaman Aşımı',
    patient_operations_table_gdpr_pending_status_text: 'Onay Bekleniyor',
    patient_operations_table_consent_pending_status_text: 'Onay Bekleniyor',
    patient_list_pending_consent_text: 'Onam Bekleniyor',
    patient_list_tip_operations_text:
      'Onam işlemleri sayfasından gönderme işlemlerini yapabilirsiniz',
    patient_operations_table_consent_unsuccesful_status_text: 'Başarısız',
    // Add Patient Popup Localization
    patient_operations_add_patient_popup_title: 'Hasta Ekle',
    patient_operations_add_patient_popup_turkish_text: 'T.C. Vatandaşı',
    patient_operations_add_patient_popup_foreign_text: 'Yabancı Uyruklu',
    patient_operations_add_patient_popup_name_text: 'Ad',
    patient_operations_add_patient_popup_surname_text: 'Soyad',
    patient_operations_add_patient_popup_tc_id_text: 'T.C. Kimlik No',
    patient_operations_add_patient_popup_passport_no_text: 'Pasaport No',
    patient_operations_add_patient_popup_dob_text: 'Doğum Tarihi',
    patient_operations_add_patient_popup_email_text: 'E-Posta',
    patient_operations_add_patient_popup_gsm_text: 'GSM',
    patient_operations_add_patient_popup_surrogate_title: 'Vekil',
    patient_operations_add_patient_popup_surrogate_name_text: 'Ad',
    patient_operations_add_patient_popup_surrogate_surname_text: 'Soyad',
    patient_operations_add_patient_popup_surrogate_tc_id_text: 'T.C. Kimlik No',
    patient_operations_add_patient_popup_surrogate_passport_no_text: 'Pasaport No',
    patient_operations_add_patient_popup_surrogate_email_text: 'E-Posta',
    patient_operations_add_patient_popup_surrogate_gsm_text: 'GSM',
    patient_operations_add_patient_popup_cancel_text: 'İptal',
    patient_operations_add_patient_popup_add_text: 'Hasta Ekle',
    patient_operations_add_patient_foreign_phone_info_text:
      '*Formlar Türk telefon numarası olmayan hastaların E-posta adresine gönderilecektir.',
    // Update Patient Popup Localization
    patient_operations_update_patient_popup_title: 'Hasta Güncelle',
    patient_operations_update_patient_popup_turkish_text: 'T.C. Vatandaşı',
    patient_operations_update_patient_popup_foreign_text: 'Yabancı Uyruklu',
    patient_operations_update_patient_popup_name_text: 'Ad',
    patient_operations_update_patient_popup_surname_text: 'Soyad',
    patient_operations_update_patient_popup_tc_id_text: 'T.C. Kimlik No',
    patient_operations_update_patient_popup_passport_no_text: 'Pasaport No',
    patient_operations_update_patient_popup_dob_text: 'Doğum Tarihi',
    patient_operations_update_patient_popup_email_text: 'E-Posta',
    patient_operations_update_patient_popup_gsm_text: 'GSM',
    patient_operations_update_patient_popup_surrogate_title: 'Vekil',
    patient_operations_update_patient_popup_surrogate_name_text: 'Ad',
    patient_operations_update_patient_popup_surrogate_surname_text: 'Soyad',
    patient_operations_update_patient_popup_surrogate_tc_id_text: 'T.C. Kimlik No',
    patient_operations_update_patient_popup_surrogate_passport_no_text: 'Pasaport No',
    patient_operations_update_patient_popup_surrogate_email_text: 'E-Posta',
    patient_operations_update_patient_popup_surrogate_gsm_text: 'GSM',
    patient_operations_update_patient_popup_cancel_text: 'İptal',
    patient_operations_update_patient_popup_add_text: 'Güncelle',
    patient_operations_update_patient_foreign_phone_info_text:
      '*Formlar Türk telefon numarası olmayan hastaların E-posta adresine gönderilecektir.',
    // Doctors Localization
    doctors_forms_table_column_title_text: 'Ünvan',
    doctors_forms_table_column_firstname_text: 'Ad',
    doctors_forms_table_column_lastname_text: 'Soyad',
    doctors_forms_table_column_gsm_text: 'GSM',
    doctors_forms_table_column_email_text: 'E-Posta',
    doctors_add_user_text: 'Doktor Ekle',
    doctors_add_user_popup_code_placeholder: 'Doktor Kodu',
    doctors_add_user_popup_code_title: 'Kod',
    // Add Surrogate Popup Localization
    patient_operations_add_surrogate_popup_title: 'Vekil Ekle',
    patient_operations_add_surrogate_popup_name_text: 'Ad',
    patient_operations_add_surrogate_popup_surname_text: 'Soyad',
    patient_operations_add_surrogate_popup_tc_id_text: 'T.C. Kimlik No',
    patient_operations_add_surrogate_popup_email_text: 'E-Posta',
    patient_operations_add_surrogate_popup_gsm_text: 'GSM',
    patient_operations_add_surrogate_popup_cancel_text: 'İptal',
    patient_operations_add_surrogate_popup_add_text: 'Vekil Ekle',
    // Surrogate List Popup Localization
    patient_operations_surrogate_list_popup_title: 'Vekil Listesi',
    patient_operations_surrogate_list_popup_table_column_name_text: 'Ad',
    patient_operations_surrogate_list_popup_table_column_surname_text: 'Spyad',
    patient_operations_surrogate_list_popup_table_column_id_text: 'T.C. Kimlik No',
    patient_operations_surrogate_list_popup_table_column_gsm_text: 'GSM',
    patient_operations_surrogate_list_popup_table_column_email_text: 'E-Posta',
    patient_operations_surrogate_list_popup_table_column_gdpr_status_text: 'KVKK Durum',
    patient_operations_surrogate_list_popup_table_column_operations_text: 'İşlemler',
    patient_operations_surrogate_list_popup_tips_update_surrogate_text: 'Vekil Güncelle',
    // Update Surrogate Popup Localization
    patient_operations_update_surrogate_popup_title: 'Vekil Güncelle',
    patient_operations_update_surrogate_popup_name_text: 'Ad',
    patient_operations_update_surrogate_popup_surname_text: 'Soyad',
    patient_operations_update_surrogate_popup_tc_id_text: 'T.C. Kimlik No',
    patient_operations_update_surrogate_popup_email_text: 'E-Posta',
    patient_operations_update_surrogate_popup_gsm_text: 'GSM',
    patient_operations_update_surrogate_popup_cancel_text: 'İptal',
    patient_operations_update_surrogate_popup_add_text: 'Vekil Güncelle',
    // Send Consent Popup Localization
    patient_operations_send_consent_popup_title: 'Onam Gönder',
    patient_operations_send_consent_popup_patient_name_text: 'Hasta Adı:',
    patient_operations_send_consent_popup_contact_choice_text: 'İletişim Tercihi:',
    patient_operations_send_consent_popup_consent_type_text: 'Onam Amacı:',
    patient_operations_send_consent_popup_consent_search_text: 'Onam Ara',
    patient_operations_send_consent_popup_table_column_consent_name_text: 'Onam Adı',
    patient_operations_send_consent_popup_table_column_consent_status_text: 'Durum',
    patient_operations_send_consent_popup_table_column_consent_version_text: 'Versiyon',
    patient_operations_send_consent_popup_table_column_consent_upload_date_text: 'Yüklenme Tarihi',
    patient_operations_send_consent_popup_table_column_consent_operations_text: 'İşlemler',
    patient_operations_send_consent_popup_consent_type_approve_text: 'İşlem Onayı',
    patient_operations_send_consent_popup_consent_type_reject_text: 'İşlem Reddi',
    patient_operations_send_consent_popup_consent_type_cancel_text: 'İşlem İptali',
    patient_operations_send_consent_popup_send_text: 'Gönder',
    patient_operations_send_consent_popup_consent_uptodate_text: 'Güncel',
    patient_operations_send_consent_popup_consent_not_uptodate_text: 'Eski',
    // Send Consent Popup Localization
    patient_operations_patient_consents_popup_title: 'Hasta Onamları',
    patient_operations_patient_consents_popup_patient_name_text: 'Hasta Adı:',
    patient_operations_patient_consents_popup_table_column_consent_name_text: 'Onam Adı',
    patient_operations_patient_consents_popup_table_column_consent_status_text: 'Durum',
    patient_operations_patient_consents_popup_table_column_consent_type_text: 'Onam Tipi',
    patient_operations_patient_consents_popup_table_column_consent_request_date_text:
      'Talep Tarihi',
    patient_operations_patient_consents_popup_table_column_consent_operations_text: 'İşlemler',
    patient_operations_patient_consents_popup_table_column_view_consent_text: 'Görüntülemek için tıklayın',
    patient_operations_patient_consents_popup_consent_type_approve_text: 'Onay',
    patient_operations_patient_consents_popup_consent_type_reject_text: 'Red',
    patient_operations_patient_consents_popup_consent_type_cance_text: 'İptal',
    // Delete Patient Popup Localization
    patient_operations_patient_delete_patient_popup_text:
      'Hastayı silmek istediğinizden emin misiniz?',
    patient_operations_patient_delete_patient_popup_cancel_text: 'İptal',
    patient_operations_patient_delete_patient_popup_delete_text: 'Sil',
    // Sponsors Localization
    sponsors_title: 'Sponsorlar',
    sponsors_table_column_name_text: 'Ad',
    sponsors_table_column_description_text: 'Açıklama',
    sponsors_table_column_operations_text: 'İşlemler',
    sponsors_add_sponsor_text: 'Sponsor Ekle',
    sponsors_operations_tips_update_sponsor_text: 'Sponsor Güncelle',
    sponsors_operations_tips_create_code_text: 'Kod Yarat',
    sponsors_operations_tips_delete_sponsor_text: 'Sponsor Sil',
    sponsors_delete_sponsor_popup_text: 'Sponsoru silmek istediğinizden emin misiniz?',
    sponsors_delete_sponsor_popup_cancel_text: 'İptal',
    sponsors_delete_sponsor_popup_delete_text: 'Sil',
    sponsors_add_sponsor_popup_name_text: 'Ad',
    sponsors_add_sponsor_popup_description_text: 'Açıklama',
    sponsors_create_code_popup_name_text: 'Ad',
    sponsors_create_code_popup_description_text: 'Açıklama',
    sponsors_create_code_popup_code_number_text: 'Kod Adedi',
    sponsors_create_code_popup_prefix_text: 'Prefix',
    sponsors_create_code_popup_end_date_text: 'Bitiş Tarihi',
    sponsors_create_code_popup_packages_text: 'Paketler',
    // News Localization
    news_title: 'Sponsorlar',
    news_table_column_title_text: 'Başlık',
    news_table_column_description_text: 'Açıklama',
    news_table_column_sponsor_name_text: 'Sponsor Adı',
    news_table_column_operations_text: 'İşlemler',
    news_add_news_text: 'Haber Ekle',
    news_add_news_popup_title_text: 'Başlık',
    news_add_news_popup_description_text: 'Açıklama',
    news_add_news_popup_target_text: 'Hedef Link',
    news_add_news_popup_image_url_text: 'Görsel URL',
    news_add_news_popup_sponsor_text: 'Sponsor',
    news_add_news_popup_select_sponsor_text: 'Sponsor Seç',
    news_operations_tips_update_news_text: 'Haber Güncelle',
    news_operations_tips_delete_news_text: 'Haber sil',
    news_delete_news_popup_text: 'Haberi silmek istediğinizden emin misiniz?',
    // Package Localization
    purchase_package_title: 'Sponsorlar',
    purchase_package_table_column_name_text: 'Paket Adı',
    purchase_package_table_column_description_text: 'Açıklama',
    purchase_package_table_column_package_duration_text: 'Paket Süresi',
    purchase_package_table_column_package_price_text: 'Fiyat',
    purchase_package_table_column_package_type_text: 'Paket Tipi',
    purchase_package_table_column_consents_text: 'Onamlar',
    purchase_package_table_column_operations_text: 'İşlemler',
    purchase_package_add_package_text: 'Paket Ekle',
    purchase_package_consent_purchase_text: 'Ödemeye İlerle',
    purchase_package_tips_view_package_text: 'İçerik Görüntüle',
    purchase_package_tips_update_package_text: 'Paket Güncelle',
    purchase_package_tips_delete_package_text: 'Paket Sil',
    purchase_package_delete_package_popup_text: 'Paketi silmek istediğinizden emin misiniz?',
    purchase_package_content_popup_consent_title: 'Paket İçeriği',
    purchase_package_content_popup_consent_text: 'Onam İsmi',
    purchase_package_content_popup_consent_detail_text: 'Onam Detayı',
    purchase_package_content_popup_consent_creation_date_text: 'Yüklenme Tarihi',
    purchase_package_update_package_text: 'Paket Güncelle',
    purchase_package_month_text: 'Ay',
    purchase_package_year_text: 'Yıl',
    purchase_package_single_text: 'Tekli',
    purchase_package_multiple_text: 'Çoklu',
    // GDPR Upload Localization
    gdpr_upload_default_gdpr_text: 'Varsayılan KVKK Olarak Ayarla',
    // Consent Upload Localization
    consent_upload_create_consent_document_text: 'Onam Oluştur',
    consent_upload_create_consent_consent_name: 'Onam Adı',
    consent_upload_create_consent_consent_description: 'Onam Açıklaması',
    consent_upload_create_consent_consent_duration: 'Onam Süresi',
    consent_upload_create_consent_consent_type: 'Onam Amacı',
    consent_upload_create_consent_consent_price: 'Fiyat',
    // Promo Codes Localization
    promo_codes_table_column_name_text: 'Promosyon Adı',
    promo_codes_table_column_quantity_text: 'Kod Adedi',
    promo_codes_table_column_sponsor_text: 'Sponsor',
    promo_codes_table_column_expiration_date_text: 'Promosyon Bitiş Süresi',
    promo_codes_table_column_operations_text: 'İşlemler',
    promo_codes_tips_view_text: 'Kodları Göster',
    promo_codes_tips_delete_text: 'Promosyonu Sil',
    promo_codes_table_column_description_text: 'Açıklama',
    promo_codes_table_column_prefix_text: 'Prefix',
    promo_codes_delete_promotion_popup_text: 'Promosyonu silmek istediğinizden emin misiniz?',
    promo_codes_codes_popup_title: 'Promosyon Kodları',
    promo_codes_codes_popup_table_column_code_text: 'Kod',
    promo_codes_codes_popup_table_column_creation_date_text: 'Oluşturulma Tarihi',
    promo_codes_codes_popup_table_column_code_status_text: 'Kullanım Durumu',
    promo_codes_codes_popup_table_column_email_text: 'E-Posta',
    promo_codes_codes_popup_table_column_usage_date_text: 'Kullanım Tarihi',
    promo_codes_codes_popup_table_column_expiry_date_text: 'Bitiş Tarihi',
    promo_codes_codes_popup_table_column_status_text: 'Durum',
    promo_codes_codes_popup_used_text: 'Kullanıldı',
    promo_codes_codes_popup_unused_text: 'Aktif',
    promo_codes_codes_popup_active_text: 'Aktif',
    promo_codes_codes_popup_expired_text: 'Bitti',
    // GDPR Forms  Localization
    gdpr_forms_table_upload_gdpr_text: 'KVKK Yükle',
    gdpr_forms_table_column_name_text: 'Form Adı',
    gdpr_forms_table_column_version_text: 'Versiyon',
    gdpr_forms_table_column_creator_text: 'Oluşturan',
    gdpr_forms_table_column_creation_date_text: 'Oluşturulma Tarihi',
    gdpr_forms_table_column_operations_text: 'İşlemler',
    gdpr_forms_table_expired_text: 'Güncel Değil',
    gdpr_forms_table_active_text: 'Aktif',
    gdpr_forms_table_activate_text: 'Aktifleştir',
    gdpr_forms_tips_view_text: 'Görüntülemek için tıklayın',
    gdpr_forms_tips_activate_text: 'Seçili KVKK olarak ayarlamak için tıklayın',

    // Profile Localization
    profile_title_text: 'Ünvan',
    profile_name_text: 'Ad',
    profile_surname_text: 'Soyad',
    profile_gsm_text: 'GSM',
    profile_save_changes_text: 'Değişiklikleri Kaydet',
    profile_current_password_text: 'Mevcut Şifre',
    profile_new_password_text: 'Yeni Şifre',
    profile_new_password_again_text: 'Şifre Tekrar',
    profile_update_password_text: 'Şifre Güncelle',
    profile_promotion_code_text: 'Promosyon Kodu',
    profile_active_promotion_code_text: 'Aktif Promosyon Kodu:',
    profile_promotion_expiration_date_text: 'Bitiş Tarihi:',
    // Billing Localization
    billing_information_address_title_text: 'Adres İsmi',
    billing_information_address_name_text: 'Ad',
    billing_information_address_surname_text: 'Soyad',
    billing_information_address_city_text: 'İl',
    billing_information_address_county_text: 'İlçe',
    billing_information_address_address_text: 'Adres',
    billing_information_address_email_text: 'E-Posta',
    billing_information_address_gsm_text: 'GSM',
    billing_information_address_corporate_address_text: 'Kurumsal Adres',
    billing_information_address_corporate_name_text: 'Şirket Adi',
    billing_information_address_tax_office_text: 'Vergi Dairesi',
    billing_information_address_tax_number_text: 'Vergi Numarası / T.C. Kimlik No',
    billing_information_address_e_invoice_owner_text: 'E-Fatura Mükellefiyim',
    billing_information_save_text: 'Kaydet',
    // Sign in Localization
    sign_in_title: 'Giriş Yap',
    sign_in_subtitle: 'Hesabınıza erişmek için giriş yapın',
    sign_in_email_text: 'E-Posta',
    sign_in_password_text: 'Şifre',
    sign_in_forgot_password_text: 'Şifremi Unuttum',
    sign_in_register_text: 'Kayıt Ol!',
    sign_in_register_question_text: 'Henüz hesabınız yok mu?',
    // Sign up Localization
    sign_up_title: 'Kayıt Ol',
    sign_up_subtitle: 'Kayıt olmak için formu doldurunuz',
    sign_up_name_text: 'Ad',
    sign_up_surname_text: 'Soyad',
    sign_up_email_text: 'E-Posta',
    sign_up_gsm_text: 'Telefon Numarası',
    sign_up_password_text: 'Şifre',
    sign_up_repeat_password_text: 'Tekrar Şifre',
    sign_up_promotion_code_text: 'Promosyon Kodu',
    sign_up_terms_and_conditions_text: 'Üyelik Koşulları ve Gizlilik Politikasını',
    sign_up_terms_accept_text: 'kabul ediyorum',
    sign_up_login_question_text: 'Hesabınız var mı?',
    sign_up_login_text: 'Giriş yap!',
    // Forgot Password Localization
    forgot_password_title: 'Şifre Sıfırlama',
    forgot_password_new_title: 'Yeni şifrenizi giriniz',
    forgot_password_subtitle:
      'Şifrenizi sıfırlamak için E-Posta adresinizi giriniz. Kısa bir süre içerisinde kayıtlı E-posta adresinize şifre sıfırlama bağlantısı gönderilecektir.',
    forgot_password_email_text: 'E-Posta',
    forgot_password_send_text: 'Gönder',
    forgot_password_back_text: 'Geri Dön',
    // OTP Input
    sms_accept_gdpr_label: 'KVKK Onay',
    sms_communication_preference_text: 'İletişim Tercihi',
    sms_send_text: 'Gönder',
    sms_accept_label: 'Onam Onay',
    sms_accept_send_again_text: 'Kodu Tekrar Gönder',
    sms_accept_approve_text: 'Onayla',
    sms_accept_send_again_question: 'Doğrulama kodu hastaya ulaşmadı mı?',
    sms_accept_code_text: 'Doğrulama Kodu',
    // Input Error Localization
    input_error_invalid_name: 'İsim ve soyisim en az iki karakterden oluşmalıdır.',
    input_error_missing_newpassword: 'Lütfen yeni şifrenizi giriniz.',
    input_error_invalid_password:
      'Şifre en az 8 karakterden oluşmalı, en az 1 rakam ve 1 büyük harf içermelidir.',
    input_error_invalid_login_password: 'Lütfen şifrenizi giriniz.',
    input_error_missing_promotion_code: 'Eksik promosyon bilgisi.',
    input_error_invalid_tc_id: 'Hatalı veya eksik T.C. Kimlik No.',
    input_error_invalid_passport_id: 'Eksik veya hatalı pasaport no.',
    input_error_invalid_dob: 'Doğum tarihi eksik veya gelecek bir tarih olamaz.',
    input_error_invalid_email: 'Hatalı veya eksik E-Posta adresi.',
    input_error_invalid_gsm: 'Hatalı veya eksik telefon numarası.',
    input_error_invalid_file_name: 'Eksik dosya ismi.',
    input_error_invalid_file_type: 'Eksik dosya tipi.',
    input_error_missing_file: 'Dosya seçilmedi.',
    input_error_missing_duration: 'Eksik onam süresi.',
    input_error_invalid_consent_information: 'Eksik onam bilgisi.',
    input_error_invalid_consent_type: 'Eksik onam amacı.',
    input_error_invalid_consent_price: 'Eksik fiyat bilgisi.',
    input_error_invalid_user_role: 'Eksik rol.',
    input_error_missing_package_name: 'Eksik paket ismi.',
    input_error_missing_package_description: 'Eksik paket açıklaması.',
    input_error_missing_package_price: 'Eksik paket fiyatı.',
    input_error_missing_package_duration: 'Eksik paket süresi.',
    input_error_missing_package_consent: 'Eksik paket onamı.',
    input_error_missing_news_name: 'Eksik haber ismi.',
    input_error_missing_news_topic: 'Eksik haber konusu.',
    input_error_missing_news_link: 'Eksik haber linki.',
    input_error_missing_news_image_link: 'Eksik haber fotoğrafı.',
    input_error_missing_news_sponsor: 'Eksik haber sponsoru.',
    input_error_missing_sponsor_name: 'Eksik sponsor ismi.',
    input_error_missing_sponsor_description: 'Eksik sponsor açıklaması.',
    input_error_missing_otp_code: 'Lütfen hastaya ait 6 haneli doğrulama kodunu giriniz.',
    input_error_missing_promotion_name: 'Eksik promosyon ismi.',
    input_error_missing_promotion_description: 'Eksik promosyon açıklaması.',
    input_error_missing_promotion_quantity: 'Eksik promosyon adedi.',
    input_error_missing_promotion_prefix: 'Eksik prefix.',
    input_error_missing_promotion_expiration_date: 'Eksik promosyon bitiş tarihi.',
    input_error_missing_promotion_package: 'Eksik promosyon paketi.',
    input_error_missing_address_name: 'Eksik adres ismi.',
    input_error_missing_address_city: 'Eksik adres ili.',
    input_error_missing_address_county: 'Eksik adres ilçesi.',
    input_error_missing_address: 'Eksik adres.',
    input_error_missing_corporate_name: 'Eksik şirket ismi.',
    input_error_missing_tax_registry: 'Eksik vergi dairesi.',
    input_error_missing_tax_number: 'Eksik vergi numarası.',
    input_error_missing_doctor_code: 'Eksik doktor kodu.',
    input_error_not_matching_password: 'Şifre eşleşmiyor.',

    // Notification Messages
    notification_successful_patient_update: 'Hasta başarıyla güncellendi.',
    notification_error_patient_update:
      'Hasta güncellerken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_patient_add: 'Hasta başarıyla eklendi.',
    notification_error_patient_add:
      'Hasta eklerken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_patient_delete: 'Hasta başarıyla silindi.',
    notification_error_patient_delete:
      'Hasta silerken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_patients_loaded: 'Hasta listesi başarıyla yüklendi.',
    notification_error_patients_loaded:
      'Hasta listesi yüklenirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_forgot_password:
      'E-Posta adresinize şifre sıfırlama bağlantısı gönderilmiştir.',
    notification_error_forgot_password:
      'Şifre sıfırlama bağlantısı gönderilirken bir problemle karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_error_missing_consent_selection:
      'Lütfen göndermek istediğiniz aydınlatılmış onam formunu seçiniz.',
    notification_error_missing_gdpr:
      'Aydınlatılmış onam göndermek için öncelikle hastanın KVKK formunun onaylanması gerekmektedir.',
    notification_error_underage:
      '18 yaşından küçük hastaların aydınlatılmış onam formlarının vekile gönderilmesi gerekmektedir.',
    notification_successful_consent_sent:
      'Bilgilendirilmiş rıza formu başarıyla hastanın iletişim tercihine yollandı.',
    notification_error_consent_sent:
      'Bilgilendirilmiş rıza formu yollanırken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_billing_info_update: 'Fatura bilgileri başarıyla güncellendi.',
    notification_error_billing_info_update:
      'Fatura bilgileri güncellenirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz..',
    notification_info_billing_info_not_found: 'Kullanıcıya ait fatura bilgisi bulunamadı.',
    notification_successful_billing_info_add: 'Fatura bilgileri başarıyla eklendi.',
    notification_error_billing_info_add:
      'Fatura bilgileri eklenirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_consent_approved: 'Bilgilendirilmiş rıza formu başarıyla onaylandı.',
    notification_error_consent_approved:
      'Bilgilendirilmiş rıza formu onaylanırken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_password_reset: 'Şifreniz başarıyla sıfırlandı.',
    notification_error_password_reset:
      'Şifreniz sıfırlanırken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_sign_up:
      'Hesabınız başarıyla oluşturuldu. Hesabınıza giriş yapmak için E-Posta adresinize gelen link üzerinden hesabınızı onaylayınız.',
    notification_successful_sign_up_secretary: 'Giriş yapmak için yönlendiriliyorsunuz.',
    notification_error_sign_up: 'Kayıt olurken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_error_sign_in: 'Giriş yaparken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_sign_in: 'Başarıyla giriş yapıldı. Ana sayfaya yönlendiriliyorsunuz.',
    notification_error_underage_gdpr:
      '18 yaşından küçük hastaların KVKK onaylarının vekile gönderilmesi gerekmektedir.',
    notification_error_missing_phone:
      'Lütfen hastanın geçerli bir cep telefonu numarasına sahip olduğundan emin olunuz.',
    notification_successful_gdpr_sent: 'KVKK formu başarıyla hastanın iletişim tercihine yollandı.',
    notification_error_gdpr_sent:
      'KVKK formu yollanırken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_gdpr_approved: 'KVKK formu başarıyla onaylandı.',
    notification_error_gdpr_approved:
      'KVKK formu onaylanırken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_news_loaded: 'Haberler başarıyla yüklendi.',
    notification_error_news_loaded:
      'Haberler yüklenirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_news_deleted: 'Haber başarıyla silindi.',
    notification_error_news_deleted:
      'Haber silerken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_news_added: 'Haber başarıyla eklendi.',
    notification_error_news_added:
      'Haber eklenirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_news_updated: 'Haber başarıyla güncellendi.',
    notification_error_news_updated:
      'Haber güncellenirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_error_get_promotions:
      'Promosyonlar yüklenirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_package_delete: 'Paket başarıyla silindi.',
    notification_error_package_delete:
      'Paket silinirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_package_load: 'Paket listesi başarıyla yüklendi.',
    notification_error_package_load:
      'Paket listesi yüklenirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_error_package_choose: 'Lütfen satın almak için bir paket seçiniz.',
    notification_successful_account_load: 'Profil bilgileri başarıyla yüklendi.',
    notification_error_account_load:
      'Profil bilgileri yüklenirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_account_update: 'Profil bilgileri başarıyla güncellendi.',
    notification_error_account_update:
      'Profil bilgileri güncellenirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_error_invalid_promotion_code:
      'Girmiş olduğunuz promosyon kodu hatalı. Lütfen kodu doğru girdiğinizi kontrol ediniz.',
    notification_successful_promotion_code_entered:
      'Promosyon kodu başarıyla hesabınıza tanımlandı.',
    notification_successful_password_change: 'Şifreniz başarıyla değiştirildi.',
    notification_error_password_change:
      'Şifre değiştirilirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    users_notification_invitation_error:
      'Davetiye gönderilirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_file_upload: 'Dosya başarıyla yüklendi.',
    notification_error_file_upload:
      'Dosya yüklenirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_patient_list_get: 'Hasta listesi başarıyla yüklendi.',
    notification_error_patient_list_get:
      'Hasta listesi yüklenirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_surrogate_added: 'Vekil başarıyla eklendi.',
    notification_error_surrogate_added:
      'Vekil eklenirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_surrogate_updated: 'Vekil başarıyla güncellendi.',
    notification_error_surrogate_updated:
      'Vekil güncellenirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_gdpr_loaded: 'KVKK formları başarıyla yüklendi.',
    notification_error_gdpr_loaded:
      'KVKK formları yüklenirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_consents_loaded: 'Onam formları başarıyla yüklendi.',
    notification_error_consents_loaded:
      'Onam formları yüklenirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_sponsor_add: 'Sponsor başarıyla eklendi.',
    notification_error_sponsor_add:
      'Sponsor eklerken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_sponsor_update: 'Sponsor başarıyla güncellendi.',
    notification_error_sponsor_update:
      'Sponsor güncellenirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_error_sponsor_delete:
      'Sponsor silinirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_sponsor_delete: 'Sponsor başarıyla silindi.',
    notification_error_sponsor_load:
      'Sponsor listesi yüklenirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_sponsor_load: 'Sponsor listesi yüklendi.',
    notification_successful_code_created: 'Promosyon kodu başarıyla yaratıldı.',
    notification_error_code_created:
      'Promosyon kodu yaratırken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_code_deleted: 'Promosyon kodu başarıyla silindi.',
    notification_error_code_deleted:
      'Promosyon kodu silinirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_code_loaded: 'Promosyon kodları başarıyla yüklendi.',
    notification_error_code_loaded:
      'Promosyon kodları yüklenirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_error_past_expiration_date:
      'Promosyon kodu bitiş tarihi geçmişte seçilemez. Lütfen tekrar deneyiniz.',
    notification_error_package_form_not_found: 'Onam grupları bulunamadı. Lütfen tekrar deneyiniz.',
    notification_successful_package_form_created: 'Paket başarıyla oluşturuldu.',
    notification_error_package_form_created:
      'Paket oluşturulurken hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_successful_package_form_update: 'Paket başarıyla güncellendi.',
    notification_error_package_form_update:
      'Paket güncellenirken hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    notification_error_mail_invitation:
      'Mail davetiyesinde bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    // General Usage
    general_table_column_text: 'Tablo Kolonları',
    general_column_filter_text: 'Kolon Filtrele',
    general_create_text: 'Yarat',
    general_cancel_text: 'İptal',
    general_delete_text: 'Sil',
    general_update_text: 'Güncelle',
    general_upload_text: 'Yükle',
    general_pick_folder_text: 'Dosya Seç',
    general_folder_name: 'Dosya Adı',
    general_search_text: 'Arama Yap',
    general_patient_text: 'Hasta',
    general_all_text: 'Hepsi',
    general_view_text: 'Görüntüle',
    general_view_tip_text: 'Görüntülemek için tıklayın',
    general_add_text: 'Ekle',
    general_search_tip_text: 'Arama yapmak için en az 3 karakter girin.',

    // Corporate Account
    edit_profile_delete_corporate_conntection_text: 'Şirket Bağlantısını Sil',
    edit_profile_corporate_area_label: 'Şirket Hesap Kodu:',
    edit_profile_delete_code_text: 'Kodu Sil',
    edit_profile_generate_corporate_code_text: 'Şirket Bağlantısı Kodu Yarat',
    edit_profile_delete_connection_notification: 'Şirket Bağlantısı Silindi',
    edit_profile_delete_connection_error_notification: 'Şirket bağlantısı silinirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    edit_profile_delete_code_notification: 'Şirket Bağlantı Kodu Silindi',
    edit_profile_delete_code_error_notification: 'Şirket bağlantı kodu silinirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    edit_profile_code_generated_notification: 'Şirket Bağlantı Kodu Yaratıldı',
    edit_profile_code_generated_error_notification: 'Şirket bağlantı kodu üretilirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    doctor_form_added_doctor_notification: 'Doktor Başarıyla Eklendi',
    doctor_form_added_doctor_error_notification: 'Doktor eklenirken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
    doctor_list_remove_doctor_notification: 'Doktor Başarıyla Silindi',
    doctor_list_remove_doctor_error_notification: 'Doktor silerken bir hatayla karşılaşıldı. Lütfen tekrar deneyiniz.',
  },
}