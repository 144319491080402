/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { Button, Input, Modal, Select, Table, Tag, Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import GetPatientConsentService from '../../../api/patient-consent/get-patient-consent';

import { IPageData } from '../../../interfaces';
import { usePageData } from '../../../hooks';
import { LanguageContext, translations } from '../../../translation';

const { Option } = Select;

const Lists = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const pageData: IPageData = {
    title: t.sidebar_consent_status_list_text,
    fulFilled: true,
    breadcrumbs: [
      {
        title: t.sidebar_mainpage_text,
        route: 'main-page'
      },
      {
        title: t.sidebar_consent_status_list_text
      }
    ]
  };
  usePageData(pageData);
  const { option }: any = useParams();
  const [data, setData] = useState([]);
  const [selectedOption, setSelectedOption] = useState<string>();
  const [filteredColumns, setFilteredColumns] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [visible, setVisible] = useState(false);
  const [filterColumns, setFilterColumns] = useState({
    name: true,
    status: true,
    type: true,
    firstName: true,
    lastName: true,
    email: false,
    tckn: false,
    createdAt: true,
    data: true
  });

  const columns = [
    {
      key: 'name',
      title: t.patient_operations_patient_consents_popup_table_column_consent_name_text,
      dataIndex: 'consentForm',
      render: (value) => <strong>{value?.name}</strong>
    },
    {
      key: 'status',
      title: t.patient_operations_patient_consents_popup_table_column_consent_status_text,
      dataIndex: 'status',
      render: (value) => (
        <Tag
          color={
            (value === 'timeout' && '#f5222d') ||
            (value === 'pending' && '#F9A602') ||
            (value === 'successful' && '#299617')
          }
        >
          {value === 'timeout'
            ? t.patient_operations_table_consent_timeout_status_text
            : value === 'pending'
            ? t.patient_operations_table_consent_pending_status_text
            : t.patient_operations_table_consent_approved_status_text}
        </Tag>
      )
    },
    {
      key: 'type',
      title: t.patient_operations_patient_consents_popup_table_column_consent_type_text,
      dataIndex: 'type',
      render: (value) => (
        <strong>
          {value === 'reject'
            ? t.patient_operations_patient_consents_popup_consent_type_reject_text
            : value === 'cancel'
            ? t.patient_operations_patient_consents_popup_consent_type_cance_text
            : t.patient_operations_patient_consents_popup_consent_type_approve_text}
        </strong>
      )
    },
    {
      key: 'firstName',
      title: `${t.general_patient_text} ${t.patient_operations_update_patient_popup_name_text}`,
      render: (value) => <strong>{value?.firstName}</strong>
    },
    {
      key: 'lastName',
      title: `${t.general_patient_text} ${t.patient_operations_update_patient_popup_surname_text}`,
      render: (value) => <strong>{value?.lastName}</strong>
    },
    {
      key: 'email',
      title: `${t.general_patient_text} ${t.patient_operations_update_patient_popup_email_text}`,
      render: (value) => <strong>{value?.email}</strong>
    },
    {
      key: 'tckn',
      title: `${t.general_patient_text} ${t.patient_operations_update_patient_popup_tc_id_text}`,
      render: (value) => <strong>{value?.tckn}</strong>
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: t.patient_operations_patient_consents_popup_table_column_consent_request_date_text,
      render: (createdAt) => <strong>{moment(createdAt).format('DD/MM/YYYY HH:mm')}</strong>
    },
    {
      key: 'data',
      title: t.patient_operations_patient_consents_popup_table_column_consent_operations_text,
      render: (data) => (
        <>
          {data?.status === 'successful' ? (
            <div>
              <Tooltip title={t.general_view_tip_text}>
                <Button
                  type='primary'
                  shape='circle'
                  onClick={() => window.open(data?.file?.url, '_blank')}
                >
                  <EyeOutlined />
                </Button>
              </Tooltip>
            </div>
          ) : (
            <></>
          )}
        </>
      )
    }
  ];
  useEffect(() => {
    const data = columns.filter((el) => filterColumns[el.key]);
    setFilteredColumns(data);
  }, [filterColumns]);

  const [filterText, setFilterText] = useState('');

  //fonksiyonlardan önce kurulum
  let typingTimer; //zamanlayıcı tanımlayıcısı
  const onKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(getTableData, 500);
  };

  //tuşa basıldığında, geri sayımı temizle
  const onKeyDown = () => {
    clearTimeout(typingTimer);
  };

  useEffect(() => {
    const filteredData = columns.filter((el) => filterColumns[el.key]);
    setFilteredColumns(filteredData);
  }, [filterColumns]);

  useEffect(() => {
    if (selectedOption !== undefined) {
      getTableData();
    }
  }, [selectedOption, currentPage]);

  useLayoutEffect(() => {
    handleChange(option === 'default' ? null : option);
  }, []);

  const getTableData = () => {
    if (filterText.length > 2 || filterText.length < 1) {
      const params = {
        search: filterText,
        relations: ['consentForm', 'file'],
        'filter.status': selectedOption ?? null,
        page: currentPage,
        limit: 10
      };
      GetPatientConsentService(params)
        .then((res) => {
          setData(res.data);
          setItemsPerPage(res?.meta?.itemsPerPage);
          setTotalItems(res?.meta?.totalItems);
        })
        .catch((err) => err);
    }
  };

  const handleChange = (value: string) => {
    setSelectedOption(value);
  };

  const onClose = () => setVisible(false);
  const pagination = {
    pageSize: itemsPerPage,
    current: currentPage,
    total: totalItems,
    showSizeChanger: false
  };
  return (
    <>
      <div>
        <div style={{ display: 'flex', marginBottom: 16 }}>
          <Button type='primary' onClick={() => setVisible(true)}>
            {t.general_table_column_text}
          </Button>
          <div style={{ maxWidth: 200, margin: '0 12px' }}>
            <Tooltip title={t.general_search_tip_text}>
              <Input
                type='text'
                id='searchInput'
                placeholder={t.general_search_text}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                onChange={(e) => setFilterText(e.target.value)}
              />
            </Tooltip>
          </div>
          <div style={{ width: 300 }}>
            <Select defaultValue={option === 'default' ? null : option} onChange={handleChange}>
              <Option value={null}>{t.general_all_text}</Option>
              <Option value='pending'>
                {t.patient_operations_table_consent_pending_status_text}
              </Option>
              <Option value='timeout'>
                {t.patient_operations_table_consent_timeout_status_text}
              </Option>
              <Option value='successful'>
                {t.patient_operations_table_consent_approved_status_text}
              </Option>
            </Select>
          </div>
          <div style={{ maxWidth: 200, maxHeight: 40, margin: '0 0 0 12px' }}>
            <Modal
              title={<h3>{t.general_column_filter_text}</h3>}
              visible={visible}
              footer={null}
              onCancel={onClose}
              destroyOnClose
            >
              {columns.map((column) => {
                return (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ width: 100 }}>{column.title}</span>
                    <input
                      type='checkbox'
                      name={column.key}
                      checked={filterColumns[column.key]}
                      onChange={() =>
                        setFilterColumns((state) => ({
                          ...state,
                          [column.key]: !filterColumns[column.key]
                        }))
                      }
                    />
                  </div>
                );
              })}
            </Modal>
          </div>
        </div>
      </div>
      <Table
        columns={filteredColumns}
        onChange={(pagination) => {
          setCurrentPage(pagination.current);
        }}
        pagination={pagination}
        dataSource={data}
        className='accent-header'
        rowKey='id'
      />
    </>
  );
};

export default Lists;
